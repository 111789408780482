.container {
  padding: 0 20px;
  .title {
    font-size: 18px;
    margin-bottom: 20px;
    margin-top: 40px;
    font-weight: 500;
    text-align: left;
    color: #1890ff;
  }

  .instructionsList {
    list-style: none;
    padding: 0;
    margin-bottom: 30px;

    li {
      display: flex;
      align-items: center;
      margin-bottom: 8px;

      span {
        text-align: justify;
        color: #9ba3af;
        line-height: 25px;
        width: 96%;
      }

      &::before {
        content: "";
        display: inline-block;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: #9ba3af; // 小圆点颜色
        margin-right: 8px;
      }
    }
  }
}
