.imageContainer {
  position: relative;
  width: 100%;
  height: 180px;

  .responsiveImage {
    width: 100%;
    height: 30vh;
  }

  .textOverlay {
    position: absolute;
    top: 50%;
    left: 50%;
    color: white;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
    margin-top: 50px;
    // background-color: rgba(0, 0, 0, 0.5);
    p {
      color: #eab305;
      font-size: 18px;
    }
    h1 {
      margin-top: 0.5em;
    }
  }
}

.marqueeContainer {
  position: absolute;
  width: 100%;
  height: 30px;
  line-height: 30px;
  bottom: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  background-color: rgba(255, 255, 255, 0.3);
  overflow: hidden; /* 隐藏超出容器部分的内容 */
  display: flex; /* 使用Flex布局 */
  white-space: nowrap; /* 防止内容换行 */
}

.marquee {
  color: black;
  white-space: nowrap;
  animation: marquee 15s linear infinite;

  .tip {
    color: purple;
  }
}

@keyframes marquee {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}
