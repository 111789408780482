html,
body {
  max-width: 100vw;
  overflow-x: hidden; /* 阻止横向滚动 */
}

.App {
  text-align: center;
  background-color: #111827;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.bottomDivider {
  border-top: 20px solid transparent; // 设置为灰色的虚线
  margin-bottom: 20px;
  width: 100%; // 确保虚线占满容器宽度
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
}

.modalContent {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  height: 350px;
  z-index: 4;
}

.modalHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  font-weight: 500;
}

.closeButton {
  width: 24px; // 或根据实际图标大小调整
  height: 24px; // 或根据实际图标大小调整
  cursor: pointer;
}

.copyButton {
  background-color: #1890ff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  width: 150px;

  span {
    padding-left: 20px;
  }
}

.modalTips {
  margin-top: 30px;
  margin-bottom: 20px;
  font-size: 17px;
  font-weight: 500;
}

.kefuImage {
  width: 90px;
  height: 90px;
}

.kefuName {
  color: #1890ff;
  margin-top: 8px;
  margin-bottom: 20px;
  cursor: pointer;
}

.copyIcon {
  cursor: pointer;
  margin-right: 3px; // 与文本框的间距
  display: inline-block;
  width: 18px;
  height: 18px;
  position: absolute;
  background: url("./imgs/copy_white.png") no-repeat;
  background-size: cover;
}

.kefuFlotButton {
  display: block;
  width: 60px;
  height: 60px;
  position: fixed;
  right: 10px;
  bottom: 90px;
  cursor: pointer;
  z-index: 2;
  opacity: 1;
}

.toTopFlotButton {
  display: block;
  width: 40px;
  height: 40px;
  position: fixed;
  right: 15px;
  bottom: 160px;
  cursor: pointer;
  z-index: 2;
  opacity: 1;
}

.firstContent {
  display: flex;
  flex-direction: column;
}

/* 媒体查询：屏幕宽度大于1024px时变为两列布局 */
@media (min-width: 1025px) {
  .firstContent {
    flex-direction: row-reverse;
    flex-wrap: wrap;
  }

  .contentItem {
    flex: 1 1 50%; /* 每个项目尝试占据50%的宽度 */
    display: flex;
    justify-content: center;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
