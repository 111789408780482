// index.module.scss
.responsiveNavBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #111827;
  color: #c7d0d9;
  position: fixed;
  top: 0px;
  width: 100vw;
  z-index: 2;
}

.logoAndText {
  display: flex;
  align-items: center;

  .iconStyle {
    margin-right: 8px;
    width: 30px;
    height: 30px;
  }

  span {
    font-size: 20px;
    font-weight: 600;
  }
}

.menuContent {
  .menuButton {
    display: block; // 确保小屏幕上默认显示菜单按钮
    background-color: transparent !important;
    border-color: transparent !important;
    width: 28px;
    height: 28px;
    cursor: pointer;
  }

  .horizontalMenu {
    span {
      color: white !important;
    }
    li::after {
      border-bottom: none !important;
    }
    li:hover {
      span {
        color: #1890ff !important;
      }
    }
    display: none; // 默认隐藏横向菜单
    width: 0px;
    // 移除背景色
    background-color: transparent !important;
    border-bottom: none !important;
  }
}

.drawerTitle {
  display: flex;
  align-items: center;

  .drawerImage {
    width: 48px; // 调整为所需尺寸
    height: 48px; // 保持图片宽高比
    margin-right: 6px; // 文本和图片之间的间距
  }

  .drawerTextContent {
    display: flex;
    flex-direction: column;
  }

  .drawerTitleText {
    font-size: 20px; // 根据需要调整
    font-weight: bold; // 标题文字加粗
    color: white;
  }

  .drawerSubtitleText {
    font-size: 12px; // 根据需要调整
    color: #d9d9d9;
  }
}

.drawerContainer,
.verticalMenu {
  background: #1890ff !important;
}

.drawerContainer {
  ul {
    border-inline-end: none !important;
  }
  li {
    margin-top: 20px !important;
    height: 50px !important;
    line-height: 50px !important;
  }
  :global(.ant-drawer-body) {
    padding: 0 35px;
    text-align: center;
  }
  :global(.ant-menu-item-selected) {
    background-color: transparent;
    border-radius: 0px;
    border-bottom: 2px solid #fff;
  }
  :global(.ant-drawer-header) {
    border-bottom: none;
  }
}

.verticalMenu {
  span {
    color: white !important;
  }
}

// 媒体查询，当屏幕宽度大于600px时
@media (min-width: 601px) {
  .menuContent {
    .menuButton {
      display: none; // 大屏幕上隐藏菜单按钮
    }

    .horizontalMenu {
      display: block !important; // 大屏幕上显示横向菜单
      width: 430px;
    }
  }
}
