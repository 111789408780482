.container {
  width: 95%;
  background-color: #1e293b;
  margin-left: 2.5%;
  border-color: #5c66b1;
  border: 1px solid #5c66b1;
  border-radius: 8px;
  padding: 10px;
  margin-top: 20px;
  color: white;
  .title {
    font-size: 24px;
    margin: 40px 0;
    text-align: center;
    font-weight: 400;
    color: #1890ff;
  }

  .threeColumns {
    display: flex;
    justify-content: space-around;
    margin-bottom: 16px;

    .column {
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        width: 70px; // 或根据实际需要调整
        height: 70px; // 限制图片大小
        margin-bottom: 8px;
      }
    }
  }

  .textRow {
    margin-bottom: 16px;
    color: #9ba3af;
    text-align: justify;
  }

  .imageTextRow {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    color: #9ba3af;

    &:hover {
      span {
        color: white;
      }
    }

    img {
      width: 30px; // 或根据实际需要调整
      height: 30px; // 限制图片大小
      margin-right: 8px;
    }
  }
}

/* 媒体查询：屏幕宽度大于1024px时变为两列布局 */
@media (min-width: 1025px) {
  .container {
    height: 526px;
    margin-left: 0;
    margin-right: -7px;
  }
}
