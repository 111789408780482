.energyContent2 {
  width: 95%;
  background-color: #1e293b;
  margin-left: 2.5%;
  border-color: #5c66b1;
  border: 1px solid #5c66b1;
  border-radius: 8px;
  padding: 10px;
  margin-top: 20px;
  height: 336px;

  .energyTitle {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    position: relative;

    .titleTxt {
      font-size: 17px;
      font-weight: 500;
      margin-left: 5px;
      color: white;
    }

    .tipsTxt {
      color: #1890ff;
      font-size: 14px;
    }

    .tipsIcon {
      cursor: pointer;
      display: inline-block;
      width: 18px;
      height: 18px;
      position: absolute;
      background: url("./imgs/wenhao.png") no-repeat;
      background-size: cover;
      right: 60px;
    }

    .tipName {
      cursor: pointer;
    }
  }

  .energyInput {
    position: relative;
    input {
      height: 52px;
      background-color: #10172a !important;
      color: white;
      border-color: transparent;
      padding-left: 70px;

      &::placeholder {
        color: #9ba3af;
        opacity: 1; /* 确保占位符的颜色不会因为默认的半透明而变浅 */
      }
    }
    .inputTip {
      display: inline-block;
      position: absolute;
      color: #9ba3af;
      line-height: 52px;
      left: 20px;
      font-size: 15px;
    }
  }

  .lineVertical {
    background-color: #9ba3af;
    height: 20px;
  }

  .energyRightButtons {
    display: flex;
    position: absolute;
    top: 13.5px;
    right: 10px;

    .energyButton {
      height: 25px;
      text-align: center;
      font-size: 14px;
      padding: 4px 6px;
      background-color: #eee;
      border: 1px solid #eee;
      border-radius: 4px;
      cursor: pointer;

      &:first-child {
        margin-right: 6px;
      }
    }

    .energyButtonActive {
      background-color: #1890ff;
      color: white;
      border-color: #1890ff;
    }
  }
}

.part2Container {
  display: flex;
  flex-direction: column;

  .dayAbleContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    span {
      display: flex;
      font-size: 14px;
      color: #94a3b8;
    }
  }

  .taxContent {
    display: flex;
    align-items: center;
    justify-content: start;
    background-color: #10172a;
    border-radius: 8px;

    .timeTitle {
      display: flex;
      font-size: 15px;
      margin-left: 20px;
      color: #9ba3af;
    }
  }
}

.toggleButton {
  padding: 10px;
  margin-left: 10px;
  .ant-radio-button-wrapper {
    background-color: white;
    color: black;
    &:hover {
      color: #1890ff;
    }
  }
  .ant-radio-button-wrapper-checked {
    background-color: #1890ff !important;
    color: white !important;
  }
}

.selected {
  background-color: #1890ff !important;
  color: white !important;
}

.costEstimate {
  text-align: right;
  margin-bottom: 20px;
  margin-top: 8px;
  color: white;

  span {
    font-size: 16px;
    font-weight: 500;
    color: orange;
  }
}

.buyNowButton {
  background-color: #1890ff;
  background: #1890ff !important;
  color: white !important;
  border: none;
  margin-bottom: 30px;
  margin: 0 auto;
  width: 98%;
  transition: transform 0.3s ease; /* 平滑变换效果 */
  &:hover {
    background-color: darken(#1890ff, 5%);
    border-color: darken(#1890ff, 5%);
    transform: scale(1.03); /* 鼠标悬停时恢复到原始大小 */
  }
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
}

.modalContent {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 85%;
  height: 390px;
  z-index: 4;
}

.modalHeader {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  span {
    text-align: center;
    font-size: 18px;
    font-weight: 600;
  }
}

.costNum {
  color: orange;
}

.saveButton {
  background-color: #1890ff;
  color: white;
  border: none;
  padding: 10px 20px;
  margin-top: 20px;
  cursor: pointer;
  width: 93%;
  border-radius: 4px;
}

.qrCode {
  margin: 20px 0;
  width: 100px;
  height: 100px;
}

.getEnergyInfo {
  font-size: 14px;
  font-weight: 600;
  margin-top: 8px;
}

.copyIcon {
  cursor: pointer;
  margin-right: 3px; // 与文本框的间距
  display: inline-block;
  width: 18px;
  height: 18px;
  position: absolute;
  background: url("../energyView/imgs/copy_orange2.png") no-repeat;
  background-size: cover;
  margin-left: 3px;
}

.payAddress {
  font-size: 15px;
  font-weight: 500;
  margin-top: 15px;
}

.payTips {
  font-size: 13px;
  font-weight: 700;
  color: #cd5f99;
  margin-top: 10px;
}

.ableEnergy {
  font-size: 18px;
  color: white;
  font-size: 18px;
  font-weight: 500;
  color: white;
  margin-top: 20px;
}

@media (max-width: 391px) {
  .payAddress {
    font-size: 13px;
  }
}

/* 媒体查询：屏幕宽度大于1024px时变为两列布局 */
@media (min-width: 1025px) {
  .energyContent2 {
    height: 526px;
    margin-left: -7px;
  }
}
