.container {
  .title {
    font-size: 24px;
    margin: 40px 0;
    text-align: center;
    color: #1890ff;
  }

  .faqItem {
    background-color: #d8e6ff; // 浅蓝色背景
    box-shadow: 0 6px 32px #1718201a; // 阴影效果
    border-bottom: 3vw solid rgba(105, 45, 216, 1); // 底部边框颜色和宽度
    padding: 20px;
    margin-bottom: 20px;
    width: 92%;
    margin-left: 4%;
    border-radius: 15px;
    height: 155px;
  }

  .faqTitle {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 15px;
    margin-top: 5px;
  }

  .faqDescription {
    font-size: 16px;
  }
}
